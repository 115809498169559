<template>
  <div class="bq-detail" >
    <van-nav-bar :title="title" left-text="返回" fixed left-arrow @click-left="goBack"  />
      <div style="padding-top: 44px"></div>
      <van-form @submit="onSubmit" >
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh(activeName)"><div class="parts-from">
        <van-field v-if="editId" v-model="data.equipment" name="设备" label="设备:" readonly />
        <van-field v-if="!editId" v-model="equipment" label="设备" placeholder="选择设备" @click ="chooseEquipment">
          <template #right-icon>
            <div @click.stop="qrCode"><van-icon name="scan" size="18" /></div>
          </template>
        </van-field>
        <van-popup v-model:show="showEqPicker" round position="bottom">
          <van-search v-model="equipmentVal" placeholder="搜索设备" />
          <van-picker
                  :columns="equipmentColumns"
                  @cancel="showEqPicker = false"
                  @confirm="equipmentConfirm"
          />
        </van-popup>
        <van-field v-model="halt" is-link label="是否停机" placeholder="选择是否停机" @click="chooseHalt"/>
        <van-popup v-model:show="showHaltPicker" round position="bottom">
          <van-picker
                  :columns="haltColumns"
                  @cancel="showHaltPicker = false"
                  @confirm="haltConfirm"
          />
        </van-popup>
        <van-field v-if="editId" v-model="data.workshop" name="车间" label="车间:" readonly />
        <van-field v-if="editId" v-model="data.line" name="生产线" label="生产线:" readonly />
        <van-field v-if="editId" v-model="data.process" name="工位" label="工位:" readonly />
        <van-field v-model="data.description" rows="3" autosize label="故障现象描述" type="textarea" maxlength="250" placeholder="请输入..." show-word-limit/>
        <van-field v-model="data.sort" name="排序" label="排序:" />
      </div>
      <van-button v-if="!editId" style="width: 240px; margin: 0 auto" block round type="primary" @click="onSubmit">提交</van-button>
    </van-pull-refresh>
    </van-form>

  </div>
</template>

<script>
  import API from '@/api/project/apply'
  import EQ from '@/api/asset/equipment'
  import wx from "weixin-js-sdk"
  import wxApi from '@/api/weixin/weixin'
  import {Toast} from "vant";
  export default {
    inject: ['goUrl', 'isEdit', 'add', 'update'],
    data(){
      return{
        data: {
          equipment_id: '',
          halt: null,
          sort: 100,
          description: ''
        },
        editId: null,
        activeName:1,
        eqList: [],
        title: null,
        newKey: null,
        isLoading: false,
        finnshWeixin: false,
        read: true,
        equipmentVal: null,
        halt: null,
        haltColumns: [
          {id: 1 ,text: '是'},
          {id: 2 ,text: '否'}
        ],
        equipmentColumns: [],
        equipmentSearch: [],
        showEqPicker: false,
        showHaltPicker: false,
        equipment: null
      }
    },
    created() {
      const edit = this.isEdit()
      this.getWxConfig()
      if (edit) {
        this.title = '报修详情'
        this.editId = edit - 0
        this.info()
      } else {
        this.title = '新增报修'
      }
    },
    methods:{
      async info() {
        const res = await API.getOne(this.editId)
        this.data = res.data
        this.haltColumns.forEach(item => {
          if(item.id === res.data.halt){
            this.halt = item.text
          }
        })
      },
      goBack() {
        this.goUrl('/project/apply')
      },
      chooseHalt() {
        this.showHaltPicker = true
      },
      onRefresh(activeName) {
        this.info()
        this.activeName = activeName
        setTimeout(() => {
          this.isLoading = false;
        }, 1000)
      },
      chooseEquipment() {
        EQ.eqList({ limit:50, add_field: 'coding' }).then(res => {
          this.eqList = res.data.data
          let arr = this.eqList.map((item) => {
            return Object.assign({}, { id: item.id, text: item.name})
          })
          this.equipmentColumns = arr
          this.equipmentSearch = arr
        })
        this.showEqPicker = true
      },
      equipmentConfirm(eq) {
        this.data.equipment_id = eq.id
        this.equipment = eq.text
        this.showEqPicker = false
      },
      haltConfirm(halt) {
        console.log(halt)
        this.data.halt = halt.id
        this.halt = halt.text
        this.showHaltPicker = false
      },
      getWxConfig() {
        const url = (window.location.href).split('#')[0]
        const _this = this
        wxApi.wxJSTicket({ url }).then(res =>{
          wx.config({
            debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
            appId: res.data.appId, // 必填，公众号的唯一标识
            timestamp: res.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.signature,// 必填，签名
            jsApiList: ['checkJsApi','scanQRCode'] // 必填，需要使用的 JS 接口列表
          })
          wx.ready(function(){
            _this.finnshWeixin = true
          })
        })
      },
      qrCode() {
        const _this = this
        wx.scanQRCode({
          needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
          scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有
          success: function (res) {
            const str = res.resultStr
            const eq = str.split('|')
            if(eq[0] !== 'e') {
              Toast('这不是设备')
            } else {
              EQ.eqList({ coding: eq[1], add_field: 'coding' }).then(res => {
                _this.equipment = res.data.data[0].name
                _this.data.equipment_id = res.data.data[0].id
              })
            }
          }
        })
      },
      onSubmit(values) {
        if (values) {
          if (this.editId) {
            this.update(API, this.data, '更新成功', '/project/apply')
          } else {
            this.add(API, this.data, '新增成功')
          }
        } else {
          console.log('error submit!!')
          return false
        }
        console.log('submit', values);
      }
    },
    watch: {
      //搜索设备
      equipmentValVal: {
        handler() {
          if (this.equipmentVal == '') {
            this.equipmentColumns = this.partsSearch
          } else {
            this.equipmentColumns = []
            EQ.eqList({name: this.equipmentVal}).then(res =>{
              this.eqList = res.data.data
              let arr = this.eqList.map((item) => {
                return Object.assign({}, { id: item.id, text: item.name, coding: item.coding })
              })
              this.equipmentColumns = arr
            })
          }
        },
        immediate: true
      }
    }
  }
</script>

<style scoped>

</style>
